export enum Language {
  ENGLISH = 'en',
  HINDI = 'hn',
  TELUGU = 'te',
  MARATHI = 'mr',
  BENGALI = 'bn',
  MALAYALAM = 'ml',
  GUJARATI = 'gu',
  KANNADA = 'kn',
  TAMIL = 'ta',
  ORIYA = 'or',
}

/** For using in options */
export const Languages = Object.keys(Language) as (keyof typeof Language)[];
export type LanguagesType = typeof Languages;

export enum QuestionSet {
  PYP = 'PYP',
  NON_PYP = 'Non PYP',
  ALL_QUESTIONS = 'All Questions',
}

export const QuestionSource = ['CRAWLED', 'MANUAL', 'UPLOAD'];

export const SignupSourceType = [
  'PREPP_ANDROID',
  'PREPP_IOS',
  'PREPP_IAS_ANDROID',
  'PREPP_WEB',
  'PREPP_CMS',
];

export const EvaluationStatus = {
  UPLOADED: 'UPLOADED',
  REVIEWED: 'REVIEWED',
  REJECTED: 'REJECTED',
  UNDER_REVIEW: 'UNDER_REVIEW',
  EVALUATED: 'EVALUATED',
};

export const SubscriptionType = ['PLUS', 'EVALUATION'];

export const UserRole = {
  ADMIN: {
    label: 'Admin',
    description: 'All Permissions',
  },
  CONTENT_MANAGER: {
    label: 'Content Manager',
    description: 'All permissions except "User", "Prepp+" and "Notifications"',
  },
  DATA_RESEARCHER: {
    label: 'Data Researcher',
    description: 'All permissions except "User", "Prepps+", "Notifications" and "Leads"',
  },
  SALES_TL: {
    label: 'Sales TL',
    description: 'Permissions of Leads and Prepp+',
  },
  FREELANCER: {
    label: 'Freelancer',
    description:
      'Permissions to create content. Read access to tag fields and All access to "Posts", "Questions", "Tests", "Videos" etc.',
  },
  TELECALLER: {
    label: 'Telecaller',
    description: 'Permissions to access telecaller dashboard',
  },
  // 'INSTRUCTOR': {},
  STUDENT: {
    label: 'Student',
    description: 'Permissions to access website and mobile app',
  },
};

export const CommentReportType = [
  'SPAM',
  'SEXUALLY_EXPLICIT_MATERIAL',
  'CHILD_ABUSE',
  'HATE_SPEECH_OR_GRAPHIC_VIOLENCE',
  'PROMOTES_TERRORISM',
  'HARASSMENT_OR_BULLYING',
  'SUICIDE_OR_SELF_INJURY',
  'MISINFORMATION',
];

export const Difficulty = ['HARD', 'MEDIUM', 'EASY'];

export const ExamTypes = ['ENTRANCE', 'BOARD', 'ABROAD'];

export const QuestionType = {
  SINGLE_ANSWER_MCQ: 'Single Answer MCQ',
  MULTIPLE_ANSWER_MCQ: 'Multiple Answer MCQ',
  RANGE_QUESTION: 'Range Answer Question',
  COMPREHENSION_QUESTION: 'Comprehension Question',
  SUBJECTIVE_QUESTION: 'Subjective Question',
};

export const TestQuestionType = {
  SINGLE_ANSWER_MCQ: 'Single Answer MCQ',
  MULTIPLE_ANSWER_MCQ: 'Multiple Answer MCQ',
  RANGE_QUESTION: 'Range Answer Question',
};

export const QuestionReportType = [
  'INCORRECT_QUESTION',
  'INCORRECT_ANSWER',
  'INCORRECT_SOLUTION',
  'FORMATTING_ISSUE',
  'ANSWER_SOLUTION_MISMATCH',
  'SPELLING_MISTAKE',
  'IMAGE_MISSING',
  'INCORRECT_QUESTION_CHAPTER',
  'WEBSITE_ISSUE',
  'OTHER',
];

export const Status = ['ACTIVE', 'INACTIVE'];

export const PaperName = {
  GS_1: 'GS 1',
  GS_2: 'GS 2',
  GS_3: 'GS 3',
  GS_4: 'GS 4',
  GS_PRELIMS: 'GS Prelims',
  ESSAY: 'ESSAY',
  CSAT: 'CSAT',
};

export const TestStatus = ['ACTIVE', 'INACTIVE', 'DRAFT', 'REVIEWED'];

export const PaymentStatus = [
  'PENDING',
  'PROCESSING',
  'SUCCESS',
  'FAILED',
  'CANCELLED',
  'REFUNDED',
  'EXPIRED',
];

export const PaymentMode = ['APPLEPAY', 'RAZORPAY'];

export const PayoutStatus = ['RECEIVED', 'PROCESSING', 'SUCCESS', 'FAILED'];

export const PayoutType = ['BANK', 'PAYTM', 'UPI'];

export const TestAttemptStatus = ['PROGRESS', 'PAUSE', 'COMPLETE'];

export const TicketStatus = ['PENDING', 'RESOLVED', 'REJECTED'];

export const TicketCategory = [
  'PROFILE',
  'TEST_SERIES',
  'CONTENT',
  'APP_RELATED_ISSUE',
  'PURCHASE_RELATED_ISSUE',
  'FACING_PROBLEM_IN_PAYMENT',
  'SOMETHING_ELSE',
];

export const SupportType = ['CALL', 'EMAIL'];

export const TestTypes = {
  PREVIOUS_YEAR: 'Previous Year Paper',
  QUIZ: 'Quiz',
  CURRENT_AFFAIR_QUIZ: 'Current Affair Quiz',
  MOCK: 'Mock Test',
  SECTIONAL: 'Sectional Test',
  CHAPTER: 'Chapter Test',
  FULL: 'Full Test',
  SCHOLARSHIP: 'Scholarship Test',
  LIVE_QUIZ: 'Live Quiz',
  LIVE_TEST: 'Live Test',
  PREVIOUS_YEAR_QUESTION: 'Previous Year Question',
  ...(process.env.REACT_APP_DEPLOY_MODE === 'CD' && { SPONSORED: 'Sponsored Test' }),
};

export const PreviousYearTypes = {
  PREVIOUS_YEAR: 'Previous Year Paper',
  PREVIOUS_YEAR_QUESTION: 'Previous Year Question',
};

export const LimitedTimeTests = ['LIVE_QUIZ', 'LIVE_TEST', 'SCHOLARSHIP'];

export const TestSeriesSectionTypes = {
  PREVIOUS_YEAR: 'Previous Year Paper',
  QUIZ: 'Quiz',
  CURRENT_AFFAIR_QUIZ: 'Current Affair Quiz',
  MOCK: 'Mock Test',
  SECTIONAL: 'Sectional Test',
  CHAPTER: 'Chapter Test',
  FULL: 'Full Test',
  PREVIOUS_YEAR_QUESTION: 'Previous Year Question',
  ...(process.env.REACT_APP_DEPLOY_MODE === 'CD' && { SPONSORED: 'Sponsored Test' }),
};

export const Calculator = ['NONE', 'NORMAL', 'SCIENTIFIC'];

export enum NotificationTargets {
  ALL_USERS = 'All Users',
  NEW_DOWNLOADS = 'New Downloads',
  EXAM_CATERGORY = 'Exam Category Subscribers',
  FREE_USERS = 'Free Users',
}

export const NotificationType = ['POST', 'TEST', 'VIDEO', 'TEST_SERIES', 'LIVE_CLASS'];

export const PostType = [
  'NOTE',
  'CURRENT_AFFAIRS',
  'NEWS',
  'EXAM_INFO',
  'JOB_UPDATES',
  'STRATEGY_NOTE',
  'TOPPER_NOTE',
  'PREVIOUS_YEAR',
];

export const PushNotificationItemType = [
  'POST',
  'TEST',
  'VIDEO',
  'TEST_SERIES',
  'APP',
  'LIVE_CLASS',
];

export const RatingFeedbackType = [
  // Test  Rating Feedback
  'FOUND_ERROS_IN_QUESTIONS_OR_SOULTIONS',
  'EXAM_PATTERN_IS_NOT_BEING_FOLLOWED',
  'FACED_TECHNICAL_ISSUE_WHILE_TAKING_TEST',
  'DIFFICULTY_LEVEL_NOT_AS_PER_EXAM',
  'TIPS_AND_TRICKS_WERE_MISSING',
  'DID_NOT_LIKE_THE_SOLUTION_METHODS',
  'EXPECTED_BETTER_QUESTIONS',
  'EXACTLY_SAME_AS_ACTUAL_EXAM',
  'LIKED_SOLUTION_METHODS_AND_TRICKS',
  'INTERESTING_AND_CONCEPTUAL_QUESTIONS',

  // Video Rating Feedback
  'AUDIO_AND_VIDEO_NOT_IN_SYNC',
  'BUFFERING_ISSUE',
  'FULL_CLASS_IS_NOT_UPLOADED',
  'CONTENT_QUALITY_WAS_NOT_GOOD',
  'NOT_HAPPY_WITH_TEACHER_PRESENTATION',
  'TEACHING_METHOD_WAS_NOT_GOOD',
  'FACED_TECHNICAL_ISSUE_DURING_VIDEO',
  'AUDIO_QUALITY_WAS_POOR',
  'FACED_TECHNICAL_ISSUE_DURING_LIVE_CLASS',
  'LIKED_TEACHER_PRESENTATION',
  'LIKED_TEACHING_PRESENTATION',
  'VIDEO_WAS_ENGAGING',
  'QUALITY_OF_VIDEO',
  'LIVE_CLASS_WAS_ENGAGING',
  'QUALITY_OF_LIVE_CLASS',
];

export const RatingItemType = ['APP', 'TEST', 'VIDEO', 'LIVE_CLASS'];

export const VideoType = [
  'LECTURE',
  'CURRENT_AFFAIRS',
  'PREPARATION_STRATEGY',
  'TOPPER_TALK',
  'PYP_ANALYSIS',
  'JOB_UPDATES',
];

export const VideoConversionStatus = [
  'PROCESSING',
  'PENDING',
  'CONVERTED',
  'DOWNLOAD_FAILED',
  'CONVERSION_FAILED',
];

export const BookTypes = ['NCERT'];

export const tagByTopicTypes = ['TOPIC', 'BOOK'];

export const PYPTags = ['PRELIMS', 'MAINS'];

export const AppScreens = {
  HOME: { filters: [], itemId: false },
  PREPP_PLUS: { filters: [], itemId: false },
  CURRENT_AFFAIR_VIDEOS_LIST: { filters: ['examIds'], itemId: false },
  VIDEO_LIST: { filters: ['examIds'], itemId: false },
  VIDEO_DETAIL: { filters: [], itemId: true, itemType: 'Video' },
  CURRENT_AFFAIR_NOTES_LIST: { filters: ['examIds'], itemId: false },
  NOTES_LIST: { filters: ['examIds'], itemId: false },
  EXAM_NEWS_LIST: { filters: [], itemId: false },
  POST_DETAIL: { filters: [], itemId: true, itemType: 'Post' },
  CURRENT_AFFAIR_QUIZZES_LIST: { filters: ['examIds'], itemId: false },
  QUIZZES_LIST: { filters: ['examIds'], itemId: false },
  PYPS_LIST: { filters: ['examIds', 'year'], itemId: false },
  EXAM_DETAIL: { filters: [], itemId: true, itemType: 'Exam' },
  LIVE_TESTS_LIST: { filters: [], itemId: false },
  LIVE_QUIZZES_LIST: { filters: [], itemId: false },
  LIVE_CLASSES_LIST: { filters: [], itemId: false },
  LIVE_CLASS_DETAIL: { filters: [], itemId: true, itemType: 'LiveClass' },
  NCERT_SECTION: { filters: [], itemId: false },
};

export enum BannerTarget {
  ALL_USERS = 'ALL_USERS',
  PLUS_USERS = 'PLUS_USERS',
  NON_PLUS_USERS = 'NON_PLUS_USERS',
  EXAM_CATEGORY = 'EXAM_CATEGORY',
}

export enum BannerRedirectType {
  APP_SCREEN = 'APP_SCREEN',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
}

export enum ShortUrlBehaviour {
  'OPEN_IN_APP' = 'Open Deep Link in mobile app',
  'OPEN_IN_BROWSER' = 'Open Deep Link URL in browser',
}

export const TestTags = ['PRELIMS', 'MAINS'];

export const VideoTags = ['PRELIMS', 'MAINS', 'WRITING'];

export const LiveClassStatus = ['SCHEDULED', 'ONGOING', 'COMPLETED'];

export const TestimonialType = ['POST', 'VIDEO'];

export const cursor = {
  pointer: '../../../pointer.png',
};

export const EVENTS = {
  STWC: 'stwc', // stroke width change
  STCC: 'stcc', // stroke color change
  MD: 'md', // mouse down
  MU: 'mu', // mouse up
  MC: 'mc', // mode change
  MDRAG: 'mdrag', // mouse drag
  MV: 'mv', // mouse move
  AS: 'as', // add shape
  PDF: 'pdf', // add pdf
  CLEAR: 'clear',
  UNDO: 'undo',
  REDO: 'redo',
  SC: 'sc', // slide change
  NS: 'ns', // new slide
  BC: 'bc', // background change
  RS: 'rs', // remove slide
  Z: 'z', // zoom
  RPDF: 'rpdf', // remove pdf
  RESET: 'reset', // reset undo, redo and shapes state
  DELSHP: 'delshp', // delete shape
  PSTWC: 'pstwc', // change pencil stroke width
  ESTWC: 'estwc', // change eraser stroke width
};

export const SOCKET_EVENTS = {
  JOIN_CLASS: 'JOIN_CLASS',
  MESSAGE: 'MESSAGE',
  INSTRUCTOR_GO_LIVE: 'INSTRUCTOR_GO_LIVE',
  TOGGLE_CHAT: 'TOGGLE_CHAT',
  PIN_MESSAGE: 'PIN_MESSAGE',
  BLOCK_USER: 'BLOCK_USER',
  END_CLASS: 'END_CLASS',
  SEND_CANVAS_DATA: 'SEND_CANVAS_DATA',
  SAVE_SLIDE_DATA: 'SAVE_SLIDE_DATA',
  SYNC_TIME: 'SYNC_TIME',
  RECORDING_STARTED: 'RECORDING_STARTED',
  LEAVE_CLASS: 'LEAVE_CLASS',
};

export const ZOOM = {
  IN: 'in',
  OUT: 'out',
};

export const MODES = {
  PENCIL: 'pencil',
  ERASER: 'eraser',
  ZOOM: 'zoom',
  SELECT: 'select',
  MARKER: 'marker',
};

export const WIDTH = {
  L1: 2,
  L2: 4,
  L3: 6,
  L4: 10,
};

export const DEFAULT_WIDTH = {
  ERASER: WIDTH.L1 * 5,
  PENCIL: WIDTH.L1,
};

export const BulkPromoCodePaymentStatus = ['PENDING', 'SUCCESS', 'EXPIRED'];

export const PromoCodePaymentStatus = ['PENDING', 'REDEEMED', 'DISABLED'];

export const PLUS_PRICE_DISCOUNTED = 99;

export const hlsUrl = 'http://43.204.69.165'; // NOTE: add hls domain

export const DateRangeType = ['TODAY', 'LAST_MONTH', 'LAST_30_DAYS'];

export const FaqType = ['GENERAL', 'PAYMENT', 'EVALUATION'];

export const UserApp = ['MAIN', 'IAS'];

export const CDApp = ['CD', 'ZOLLEGE'];

export const LeadStage = {
  PROSPECT: 'Prospect',
  DNP: 'DNP',
  FOLLOW_UP: 'Follow Up',
  INTERESTED: 'Interested',
  FUTURE_INTERESTED: 'Future Interested',
  NOT_INTERESTED: 'Not Interested',
  PLUS_ACTIVE: 'Plus Active',
};

export const TIME = {
  ONE_MINUTE: 60 * 1000,
  ONE_DAY: 24 * 60 * 60 * 1000,
};

export const PlusPlanInfo = {
  ONE_MONTH: {
    MAIN: { max: 499, min: 99, price: 299 },
    IAS: { min: 99, max: 2499, price: 1499 },
  },
  ONE_YEAR: {
    MAIN: { max: 1499, min: 149, price: 349 },
    IAS: { min: 149, max: 7999, price: 1999 },
  },
  TWO_YEAR: {
    MAIN: { max: 2499, min: 199, price: 499 },
    IAS: { min: 199, max: 12999, price: 2999 },
  },
  SIX_MONTH: {
    MAIN: { max: 2499, min: 199, price: 499 },
    IAS: { min: 199, max: 12999, price: 2999 },
  },
};
