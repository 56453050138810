import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  toGqlVariables,
  toGqlArgs,
  LANGUAGE_FIELDS,
} from '../utils';

const TEST_SERIES_ARGS = {
  testIds: '[ID!]',
  examCategoryId: 'ID',
  examId: 'ID',
  publishedOnly: 'Boolean = false',
  paid: 'Boolean',
  isPrimary: 'Boolean',
  cdApp: 'CdApp',
};
const SEARCH_ARGS = { ...COMMON_SEARCH_ARGS, ...TEST_SERIES_ARGS };
const LIST_ARGS = { ...COMMON_LIST_ARGS, ...TEST_SERIES_ARGS };

const commonFields = `
  id
  name { ${LANGUAGE_FIELDS} }
  status
  order
  publishDate
  paid
  isPrimary
  cdApp
`;

const listFields = `
  ${commonFields}
  exam {
    id
    shortName { ${LANGUAGE_FIELDS} }
  }
  createdAt
  updatedAt
`;

const GET = gql`
  query GetTestSeries($id: ID!) {
    testSeries(id: $id) {
      ${commonFields}
      sections {
        testType
        testIds
        subSections {
          name
          testIds
        }
      }
      examId
      webDescription
      seo {
        title
        description
        keywords
      }
      slug
      imageId
      image {
       id
       url
      }
    }
  }
`;

const LIST = gql`
  query ListTestSeries${toGqlVariables(LIST_ARGS)} {
    items: allTestSeries${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${listFields}
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateTestSeries($input: CreateTestSeriesInput!) {
    createTestSeries(input: $input) {
      ${listFields}
    }
  }
`;

const UPDATE = gql`
  mutation UpdateTestSeries($input: UpdateTestSeriesInput!) {
    updateTestSeries(input: $input) {
      ${listFields}
    }
  }
`;

const SEARCH = gql`
  query SearchTestSeries${toGqlVariables(SEARCH_ARGS)} {
    items: allTestSeries${toGqlArgs(SEARCH_ARGS)} {
      nodes {
        value: id
        label: name { ${LANGUAGE_FIELDS} }
      }
    }
  }
`;

export { GET, LIST, CREATE, UPDATE, SEARCH };
